import {debounce} from 'debounce';

/**
 * Handles Header related events
 */
class Header {
    constructor(selector) {
        if(!selector){
            return;
        }

        let header = document.querySelector(selector);

        if(!header){
            return
        }

        this.header = header;
        this.isDarkHeader = header.classList.contains('-dark');
        
        this.addEventListeners();
    }

    /**
     * Add event listeners for the header
     *
     * @return  {void}
     */
    addEventListeners(){
        window.addEventListener('scroll', debounce(() => this.maybeChangeHeaderColour(), 8, true));
    }

    /**
     * Add/remove a class from the header depending on the scroll position
     *
     * @return  {void}
     */
    maybeChangeHeaderColour()
    {
        if(!this.isDarkHeader){
            return;
        }

        if(window.pageYOffset == 0){ // Top of page
            this.header.classList.remove('-scroll');
        } else {
            this.header.classList.add('-scroll');
        }
    }
}

export default Header;