class SocialShare {
    /**
     * Check for webShare support and add click
     * handler or remove button as approriate.
     */
    constructor() 
    {
        if(!$(".webshare-list-item").length) {
            return;
        }
        
        $(document).on('ready', () => {
            if (navigator.share) {
                this.addWebShareButtonClickHandler();
                this.removeSocialSharebuttons();
            } else {
                this.addOpenLinksHandler();
            }
        });
    }

    addOpenLinksHandler()
    {
        const btn = document.querySelector(".webshare-button");
        btn.addEventListener("click", function() {
            const links = btn.closest('.social-links');
            links.classList.toggle('-open');
        });
    }

    /**
     * Share on click
     */
    addWebShareButtonClickHandler() 
    {
        const btn = document.querySelector(".webshare-button");
        btn.addEventListener("click", function() {
            navigator.share({
                title: $("head title").text(),
                text: $('[property="og:description"]').prop('content'),
                url: location.href
            });
        });
    }
    /**
     * Remove plain share buttons in favor
     * of the WebShare button
     */
    removeSocialSharebuttons() 
    {
        $(".social-links li:not(.webshare-list-item)").each(function(){
            $(this).remove();
        });
    }
}

export default SocialShare;
