import { debounce } from "debounce";
import { isOnDesktop } from "./utils";

export default class MobileMenu {
    constructor() {
        this.openButton = document.querySelector(".open");
        this.closeButton = document.querySelector(".close");

        this.overlay = document.querySelector('.gradient-overlay');

        this.target = document.querySelector(
            "#" + this.openButton.getAttribute("aria-controls")
        );

        this.openButton.onclick = e => this.open();
        this.closeButton.onclick = e => this.close();

        this.addResizeListener();
    }
    addResizeListener() {
        window.addEventListener('resize', debounce(() => {
            if(isOnDesktop()){
                this.allowBodyScroll();
            } else {
                if(this.isOpen()){
                    this.stopBodyScoll();
                }
            }
        }, 100));
    }
    stopBodyScoll(){
        document.body.classList.add('-no-scroll');
    }
    allowBodyScroll(){
        document.body.classList.remove('-no-scroll');
    }
    isOpen() {
        return this.target.classList.contains('-open');
    }
    open() {
        this.openButton.setAttribute("aria-expanded", "true");
        this.closeButton.setAttribute("aria-expanded", "true");

        this.target.classList.add("-open");
        this.overlay.classList.add("-show");

        this.target.querySelector('ul').firstChild.querySelector('button, a').focus();

        this.stopBodyScoll();
    }
    close() {
        this.openButton.setAttribute("aria-expanded", "false");
        this.closeButton.setAttribute("aria-expanded", "false");

        this.target.classList.remove("-open");
        this.overlay.classList.remove("-show");

        this.openButton.focus();

        this.allowBodyScroll();
    }
}
