import eventPath from "./event-path";
import {isOnDesktop} from './utils';

export default class SubMenu {
    constructor(submenu) {
        this.ul = submenu;
        this.li = submenu.parentNode;
        this.a = submenu.previousElementSibling;

        this.isDarkNav = document.querySelector('.header.-primary').classList.contains('-dark');
        this.isTopLevelMenu = $(this.ul).parents('.sub-menu').length === 0;
        this.init();
    }
    init() {
        this.handleClick();
        this.handleBlur();
        this.a.classList.add("dropdown");
        this.a.setAttribute("role", "button");
        this.a.setAttribute("aria-haspopup", "true");
        this.close();

        /* Copy top level link into its submenu */
        this.copyParentToSubMenu();
    }
    handleClick() {
        this.a.onclick = e => {
            if(!isOnDesktop()){
                e.preventDefault();
                this.toggle();
            }
        };
        $(this.li).on('mouseenter', e => {
            if(isOnDesktop()){
                e.preventDefault();
                this.open();
            }
        }).on('mouseleave', e => {
            if(isOnDesktop()){
                e.preventDefault();
                this.close();
            }
        });
        this.a.onkeydown = e => {
            if (e.key === " " || e.key === "Spacebar" /* Space */) {
                e.stopPropagation();
                e.preventDefault();
            }
        };
        this.a.onkeyup = e => {
            if (e.key === " " || e.key === "Spacebar" /* Space */) {
                this.toggle();
            }
        };
    }
    handleBlur() {
        const closeOnBlur = e => {
            if (
                !eventPath(e).includes(this.ul) &&
                !eventPath(e).includes(this.a)
            ) {
                this.close();
            }
        };
        document.documentElement.addEventListener("click", closeOnBlur, false);
        document.documentElement.addEventListener("keyup", closeOnBlur, false);
    }
    toggle() {
        if (this.a.getAttribute("aria-expanded") == "false") {
            this.open();
        } else {
            this.close();
        }
    }
    isOpen(){
        return this.a.getAttribute('aria-expanded') == "true";
    }
    open() {
        this.a.setAttribute("aria-expanded", "true");

        if(this.isTopLevelMenu){
            document.querySelector('.header.-primary').classList.remove('-dark');
            document.querySelector('.gradient-overlay').classList.add('-show');
        }

    }
    close() {
        this.a.setAttribute("aria-expanded", "false");

        if(this.isDarkNav && this.isTopLevelMenu){
            document.querySelector('.header.-primary').classList.add('-dark');
        }

        if(this.isTopLevelMenu) {
            document.querySelector('.gradient-overlay').classList.remove('-show');
        }
    }
    copyParentToSubMenu(){
        let url = this.a.href;
        let text = this.a.textContent;

        let copiedLink = this.createSubMenuItem({
            url,
            text
        });

        this.ul.insertBefore(copiedLink, this.ul.firstChild);
    }

    createSubMenuItem({url, text}){
        let listItem = document.createElement('li');
        listItem.classList.add('menu-item');
        listItem.classList.add('-duplicate');

        let linkElement = document.createElement('a');
        linkElement.textContent = text;
        linkElement.setAttribute('href', url);

        listItem.appendChild(linkElement);

        return listItem;
    }
}
