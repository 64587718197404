export function isOnDesktop(){
    return window.innerWidth >= 1400;
}

/**
 * Are we on IE11?
 * 
 * @link https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
 * 
 * @return  {boolean} True if on IE11, false otherwise
 */
export function isIE11() {
   return !!window.MSInputMethodContext && !!document.documentMode;
}